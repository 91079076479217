import React, { useState, useEffect, useContext } from 'react'
import * as Styles from "../../style/home.module.scss"
import { Navbar, Nav, Button, Container } from "react-bootstrap"
import { navigate } from 'gatsby';
const CallToAction = ({ title, size, type }) => {
    const comingsoon = () => {
         
        navigate("/coming-soon")
      }
    console.log("type", type)
    return (
        type == "outlined" ?
            <Button className={size == "xl" ? Styles.callToActionSecondXl : Styles.callToActionSecondSm} onClick={()=>comingsoon()}>{title}</Button>
            :
            <Button className={size == "xl" ? Styles.callToActionXl : Styles.callToActionSm} onClick={()=>comingsoon()}>{title}</Button>
    )
} 

export default CallToAction