import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import NavbarComponent from "../components/Navbar"
import { Icon } from 'react-icons-kit'
import { facebookOfficial } from 'react-icons-kit/fa/facebookOfficial'
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
import { youtubePlay } from 'react-icons-kit/fa/youtubePlay'
import { twitter } from 'react-icons-kit/fa/twitter'
import { Row, Container, Col, Tab, Nav, Button, Form } from "react-bootstrap";
import * as Styles from "../style/home.module.scss";
import logoSVG from "../assets/images/Logo.png";


const footer = () => {
    return (
        <>
            <div style={{ background: "#172B4D", paddingTop: 30, paddingBottom: 30, borderBottomWidth: .5, borderBottomColor: "white", borderBottomStyle: "solid",overflowX:"hidden" }}>
                <Container>
                    <Row>
                        <Col md={4}>
                            <div>
                                <Link to="/">  <img src={logoSVG} alt="Skillelevator Logo" style={{ width: 250 }} /></Link>
                            </div>
                        </Col>
                        <Col md={8} className={Styles.fontWhite}>
                            <ul className={`${Styles.footerNavtigation}`}>
                                <Link to="/coming-soon">  <li className={Styles.listItem}>Faq</li></Link>
                                <Link to="/coming-soon"> <li className={Styles.listItem}>Support</li></Link>
                                <Link to="/coming-soon"> <li className={Styles.listItem}>Privacy Policy</li></Link>
                                <Link to="/coming-soon"> <li className={Styles.listItem}>Terms and conditions</li></Link>
                            </ul></Col>
                    </Row>
                </Container>
            </div>
            <div style={{ background: "#172B4D", }}>
                <Container>
                    <Row>
                        <Col md={12} style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <ul className={Styles.footerNavtigationIcons} style={{ paddingLeft: 0 }}>
                                <li className={Styles.listItem} style={{ color: "white" }}><a href="https://www.facebook.com/yourskillelevator" ><Icon icon={facebookOfficial} size={15}  className={Styles.Icons}/></a></li>
                                <li className={Styles.listItem} style={{ color: "white" }}><a href="https://twitter.com/skillelevator"><Icon icon={twitter} size={15}  className={Styles.Icons}/></a></li>
                                <li className={Styles.listItem} style={{ color: "white" }}><a  href="https://www.linkedin.com/company/skillelevator/"><Icon icon={linkedinSquare} size={15}  className={Styles.Icons}/></a></li>
                                <li className={Styles.listItem} style={{ color: "white" }}><a  href><Icon icon={youtubePlay} size={15}  className={Styles.Icons}/></a></li>
                            </ul>
                            <p  className={Styles.footerpara}> © 2021, All Rights Reserved Powered By Skill Elevator</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default footer