// extracted by mini-css-extract-plugin
export var img1 = "home-module--img1--AW+jt";
export var logo = "home-module--logo--FORa8";
export var span1 = "home-module--span1--HyVU1";
export var span2 = "home-module--span2--iNjtr";
export var div1 = "home-module--div1--edf87";
export var div2 = "home-module--div2--IZJPM";
export var CustomNavLink = "home-module--CustomNavLink--Vfq1r";
export var callToActionSm = "home-module--callToActionSm--yoSmn";
export var callToActionXl = "home-module--callToActionXl--6UvNa";
export var callToActionMd = "home-module--callToActionMd--2gdp+";
export var contactus_bg = "home-module--contactus_bg--1jKGH";
export var callToActionMdOutlined = "home-module--callToActionMdOutlined--wfjXK";
export var callToActionMdOutlined_v1 = "home-module--callToActionMdOutlined_v1--diO9j";
export var callToAction2 = "home-module--callToAction2--vyQmf";
export var callToActionSecondSm = "home-module--callToActionSecondSm--eB7pa";
export var callToActionSecondXl = "home-module--callToActionSecondXl--FPbWK";
export var banner = "home-module--banner--UcVpV";
export var banner_aboutus = "home-module--banner_aboutus--6rwD4";
export var banner_contactus = "home-module--banner_contactus--7-ipW";
export var banner_comingsoon = "home-module--banner_comingsoon--3J2zk";
export var comingsoon = "home-module--comingsoon--eTCSO";
export var banner_whySkillElevator = "home-module--banner_whySkillElevator--Qy2Rs";
export var startup = "home-module--startup--QvPsL";
export var about_row_1 = "home-module--about_row_1--j1NKl";
export var focus_row = "home-module--focus_row--hwLDe";
export var BizLab = "home-module--BizLab--TAEt4";
export var nutshell = "home-module--nutshell--0-ECt";
export var nutshellSection = "home-module--nutshellSection--0qEfc";
export var bannerHeading_comingSoon = "home-module--bannerHeading_comingSoon--gm-RU";
export var nutshellSectionImage = "home-module--nutshellSectionImage--+Poph";
export var JoSIM = "home-module--JoSIM--BddPv";
export var welcome = "home-module--welcome--jDNas";
export var what_is_skill_elevator_platform = "home-module--what_is_skill_elevator_platform--c+raq";
export var why_skill_elevator = "home-module--why_skill_elevator--voQKs";
export var bussinessLaboratory = "home-module--bussinessLaboratory--zwBnc";
export var blJobSimulator = "home-module--blJobSimulator--2JKMx";
export var roadmap = "home-module--roadmap--v4hRO";
export var roadmap_vision = "home-module--roadmap_vision--A6xaP";
export var roadmap_bg = "home-module--roadmap_bg--7EEgH";
export var idealogy = "home-module--idealogy--2f6kZ";
export var idealogy_bg = "home-module--idealogy_bg--NVZVJ";
export var text2 = "home-module--text2--UqbPw";
export var text3 = "home-module--text3--3FSgW";
export var accordionButton = "home-module--accordionButton--aJDRy";
export var fontWhite = "home-module--fontWhite--xyIHB";
export var center = "home-module--center--W946c";
export var fontBlueDark = "home-module--fontBlueDark--aV8xw";
export var bannerText = "home-module--bannerText--IOvDr";
export var TextJustify = "home-module--TextJustify--KTf2U";
export var TextJustifyWhite = "home-module--TextJustifyWhite--X5HtK";
export var TextJustifyWhiteSmall = "home-module--TextJustifyWhiteSmall--Kgv-W";
export var TextLeftBold_v1 = "home-module--TextLeftBold_v1--A2Th-";
export var TextLeftBold = "home-module--TextLeftBold--ltZbE";
export var TextLeftBoldSmall = "home-module--TextLeftBoldSmall--pwuo3";
export var TextLeftBoldWhite = "home-module--TextLeftBoldWhite--igSFp";
export var card = "home-module--card--ifQa5";
export var card_left_bg = "home-module--card_left_bg--XH4S9";
export var card_right_bg = "home-module--card_right_bg--dDXPq";
export var highlight = "home-module--highlight--nBjGT";
export var shadow1 = "home-module--shadow1--vfcqa";
export var shadow2 = "home-module--shadow2--lQvBr";
export var TextCenterBold = "home-module--TextCenterBold--JHAr5";
export var TextCenterBoldWhiteSmall = "home-module--TextCenterBoldWhiteSmall--17JGS";
export var TextCenterBoldNormal = "home-module--TextCenterBoldNormal--yB9Mf";
export var TextJustifyBoldWhiteSmall = "home-module--TextJustifyBoldWhiteSmall--bGIn0";
export var paddingTop20 = "home-module--paddingTop20--RbBOL";
export var paddingTop30 = "home-module--paddingTop30--Lxgmp";
export var paddingLeft20 = "home-module--paddingLeft20--eUeni";
export var lineHeight40 = "home-module--lineHeight40--dLYgs";
export var lineHeight30 = "home-module--lineHeight30--LH+5C";
export var TextJustifyBoldSmall = "home-module--TextJustifyBoldSmall--oUCPd";
export var TextCenterBoldBlackSmall = "home-module--TextCenterBoldBlackSmall--+e26l";
export var TextCenterBlackSmall = "home-module--TextCenterBlackSmall--F4VN6";
export var TextCenterBoldWhite = "home-module--TextCenterBoldWhite--HEAEu";
export var paddingTop10 = "home-module--paddingTop10--P1AT3";
export var bold = "home-module--bold--Btx+O";
export var TextLeftBlackSmall = "home-module--TextLeftBlackSmall--ZAudz";
export var paddingTop150 = "home-module--paddingTop150--agAEG";
export var paddingTop100 = "home-module--paddingTop100--O6WNg";
export var paddingBottom100 = "home-module--paddingBottom100--itj2s";
export var paddingBottom60 = "home-module--paddingBottom60--TcCyI";
export var paddingBottom30 = "home-module--paddingBottom30--RUDGN";
export var paddingTop60 = "home-module--paddingTop60--nWi3p";
export var paddingTop90 = "home-module--paddingTop90--0WdkL";
export var padding100 = "home-module--padding100--DXze5";
export var aboutHeading = "home-module--aboutHeading--LEQVo";
export var challengeHeading = "home-module--challengeHeading--kE5FQ";
export var aboutSection = "home-module--aboutSection--cunCN";
export var challengesSection = "home-module--challengesSection--1IIFs";
export var challengesBackground = "home-module--challengesBackground--RhlkP";
export var aboutCards = "home-module--aboutCards--hGMvr";
export var challengeCards = "home-module--challengeCards--gIcc5";
export var whyCards = "home-module--whyCards--HkW6D";
export var roadmap_cards = "home-module--roadmap_cards--PUxGY";
export var objectiveCards = "home-module--objectiveCards--iIM0Q";
export var aboutDetails = "home-module--aboutDetails--NRHQ2";
export var challengeDetails = "home-module--challengeDetails--LDtIV";
export var challengeDetails_v1 = "home-module--challengeDetails_v1--6bO8M";
export var aboutTitle = "home-module--aboutTitle--+-ILn";
export var aboutTitle_v1 = "home-module--aboutTitle_v1--wLWO8";
export var clientSection = "home-module--clientSection--MKBbU";
export var bizLabSection = "home-module--bizLabSection--LrFgk";
export var bizLabHeading = "home-module--bizLabHeading--tGAC4";
export var dashBoardFetures = "home-module--dashBoardFetures--0d6Cl";
export var checkButton = "home-module--checkButton--XQpmR";
export var checkicon = "home-module--checkicon--wspUq";
export var checkicon1 = "home-module--checkicon1--5cFAZ";
export var heightFull = "home-module--heightFull--8K4lP";
export var height80 = "home-module--height80--m8TE+";
export var dashboardAbstract = "home-module--dashboardAbstract--Lo7FW";
export var dasboardImage = "home-module--dasboardImage--Z6wVF";
export var roundImage = "home-module--roundImage--4F+zM";
export var dSectionHeading = "home-module--dSectionHeading--s-ACf";
export var caretRightIcon = "home-module--caretRightIcon--DTo3a";
export var colorGrey = "home-module--colorGrey---JONy";
export var colorBlue = "home-module--colorBlue--yL2U9";
export var list = "home-module--list--BCHbH";
export var fontSize14 = "home-module--fontSize14--PBA0O";
export var testimonials = "home-module--testimonials--HYusv";
export var testimonialsRow = "home-module--testimonialsRow--+76n5";
export var name = "home-module--name--Ey-aW";
export var whySkillElevatorSection = "home-module--whySkillElevatorSection--R+LqI";
export var bussinessLaboratoryModules = "home-module--bussinessLaboratoryModules--R7Xog";
export var blModuleFlow = "home-module--blModuleFlow--Nk4XL";
export var blJobFlow = "home-module--blJobFlow--EQBn-";
export var PlatformActionButtons = "home-module--PlatformActionButtons--EimSb";
export var bussinessLaboratoryJobRoles = "home-module--bussinessLaboratoryJobRoles--BYLrj";
export var abstract3 = "home-module--abstract3--IyUTH";
export var abstract4 = "home-module--abstract4--8EYai";
export var mockImage1 = "home-module--mockImage1--LVUoq";
export var feedback = "home-module--feedback--pr4ru";
export var feedbackContainer = "home-module--feedbackContainer--RgTxq";
export var abstract1 = "home-module--abstract1--yDgcm";
export var quote = "home-module--quote--XF84z";
export var boyImage = "home-module--boyImage--W0nEM";
export var customTabContainer = "home-module--customTabContainer--iK2B6";
export var contactSection = "home-module--contactSection--A7tmC";
export var chontactAbstract = "home-module--chontactAbstract--CI2HB";
export var fontBlue = "home-module--fontBlue--gnxKd";
export var customInput = "home-module--customInput--Nc6ys";
export var customTextarea = "home-module--customTextarea--lo3te";
export var contactConatiner = "home-module--contactConatiner--WFFYd";
export var footerNavtigation = "home-module--footerNavtigation--YhgDO";
export var listItem = "home-module--listItem--ogoK7";
export var mockImage = "home-module--mockImage--V5zz-";
export var removePadding = "home-module--removePadding--kCRN+";
export var presentationImage = "home-module--presentationImage--q4Cis";
export var chontactAbstractMobile = "home-module--chontactAbstractMobile--GfjL3";
export var navbarToggler = "home-module--navbar-toggler--3d6mX";
export var bizLogo = "home-module--bizLogo--xDm2+";
export var bannerHeading = "home-module--bannerHeading--GH5Gb";
export var bannerHeadingLeft = "home-module--bannerHeadingLeft--NVF2I";
export var mock3 = "home-module--mock3--C3MLP";
export var joSimLogo = "home-module--joSimLogo--2+-aZ";
export var angle1 = "home-module--angle1--qPNX-";
export var angle2 = "home-module--angle2--9Dp88";
export var feebackPadding = "home-module--feebackPadding--8D8QY";
export var customNavPills = "home-module--customNavPills--c8Y1X";
export var custonNavLink = "home-module--custonNavLink--sqAYu";
export var mobilePadding20 = "home-module--mobilePadding20--mrvn3";
export var proAccountantButton = "home-module--proAccountantButton--iTPo+";
export var testing = "home-module--testing--568KP";
export var content = "home-module--content--IvLOr";
export var John = "home-module--John--bEtiI";
export var child1 = "home-module--child1--No3VR";
export var child2 = "home-module--child2--pZlAn";
export var forms = "home-module--forms--u2yrr";
export var submitbtn = "home-module--submitbtn--GzMXL";
export var cancelbtn = "home-module--cancelbtn--SEoCy";
export var imgcontainer = "home-module--imgcontainer--NU3dZ";
export var container = "home-module--container--SuRte";
export var psw = "home-module--psw--9FBnj";
export var paragarph = "home-module--paragarph--CAR26";
export var images = "home-module--images--d4dlJ";
export var icons = "home-module--icons--3dZA2";
export var child3 = "home-module--child3--1bBux";
export var horizantaline = "home-module--horizantaline--rgJcw";
export var location = "home-module--location--Pt2r5";
export var Contactheading = "home-module--Contactheading--0yjaS";
export var samecontent = "home-module--samecontent--+OzkL";
export var topcontent = "home-module--topcontent--36+gq";
export var Contactourteam = "home-module--Contactourteam--+WBC3";
export var getintouch = "home-module--getintouch--gJAw8";
export var parent = "home-module--parent--iEgjW";
export var contactasimage = "home-module--contactasimage--s4ytd";
export var Contactuspage = "home-module--Contactuspage--4GD-g";
export var fontWhites = "home-module--fontWhites--HhKLB";
export var footerNavtigationIcons = "home-module--footerNavtigationIcons--bjxgv";
export var footerpara = "home-module--footerpara--hRadg";
export var nutshel = "home-module--nutshel--qlRSP";
export var SkillElevatorinanutshell = "home-module--SkillElevatorinanutshell--RIN9X";
export var headingtag = "home-module--headingtag--OhTnW";
export var navbarLight = "home-module--navbar-light--PmkAx";
export var customToggler = "home-module--custom-toggler--UAajg";
export var navbarTogglerIcon = "home-module--navbar-toggler-icon--Rf7UN";
export var digitalplatform = "home-module--digitalplatform--czz-M";
export var hrline = "home-module--hrline--fGB+U";
export var jobsimulator = "home-module--jobsimulator--XdQgG";
export var whyskill = "home-module--whyskill--3VFaJ";
export var Icons = "home-module--Icons--HYx7a";